'use client'
import React, { useEffect, useState } from 'react';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useRouter, useSearchParams } from 'next/navigation';
import Button from '@/components/UI/Button';
import Tooltip from '@/components/UI/Tooltip';
import SwapSvg from './svg/swap.svg';
import FixedDisableSvg from './svg/fixed_disable.svg';
import FixedActiveSvg from './svg/fixed_active.svg';
import ExchangeCurrency from './Currency';
import WidgetBlock from '@/components/UI/WidgetBlock';
import Skeleton from '@/components/UI/Skeleton';
import InputAmount from './UI/InputAmount';
import { ExchangeService } from '@/service/exchange';
import { useTranslation } from 'react-i18next';
import { CurrencyFromData, CurrencyToData, NestedPairToData } from '@exchanger/common'
import { ExchangerPairs } from '@/service/exchange/types'
import { routerLink } from '@/constant/router-link.const'



const ExchangeWidget = () => {
  const router = useRouter();
  const [fromSearch, setFromSearch] = useState<string>('');
  const [toSearch, setToSearch] = useState<string>('');
  const [fromAmountValue, setFromAmountValue] = useState('');
  const [toAmountValue, setToAmountValue] = useState('');
  const [isFixed, setIsFixed] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [isFirstReqAmount, setIsFirstReqAmount] = useState(true);
  const [triggeredRefetchAmount, setTriggeredRefetchAmount] = useState(false);
  const [currencyFromKey, setCurrencyFromKey] = useState('');
  const [currencyToKey, setCurrencyToKey] = useState('');
  const [currencyFrom, setCurrencyFrom] = useState<any>('');
  const [currencyTo, setCurrencyTo] = useState<any>('');
  const [isRefetchAmount, setRefetchAmount] = useState(false);
  const [pairsData, setPairsData] = useState<ExchangerPairs>();
  const [errorInputAmount, setErrorInputAmount] = useState('');
  const [getMinMaxAmount, setMinMaxAmount] = useState('');
  const [blockButtonExchange, setBlockButtonExchange] = useState(false);
  const searchParams = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fromSearch = searchParams.get('from') ?? searchParams.get('cur_from') ?? 'false';
    const toSearch = searchParams.get('to') ?? searchParams.get('cur_to') ?? 'false';
    if(fromSearch !== null && toSearch !== null) {
      setToSearch(toSearch)
      setFromSearch(fromSearch)
    }
  }, [searchParams]);

  const {
    data: pairsDataFetch,
    isSuccess: isSuccessPairs,
  } = useQuery({
    queryKey: ["pairs", fromSearch, toSearch],
    queryFn: ({ queryKey }: QueryFunctionContext<[string, string, string]>) => {
      const [, fromSearch, toSearch] = queryKey;
      return ExchangeService.getPairs(fromSearch, toSearch);
    },
    refetchOnWindowFocus: false,
    enabled: isFirstReqAmount && !!fromSearch && !!toSearch,
  });

  const {
    data: amountData,
    isFetched: isSuccessAmount,
    error: errorAmount,
    isError: isErrorAmount,
    refetch: refetchAmount,
  } = useQuery({
    queryKey: ['amount', { from: pairsData?.currencyFromKey, to: pairsData?.currencyToKey, isReverse, isFixed }],
    queryFn: () => ExchangeService.getAmount({
      from: currencyFromKey,
      to: currencyToKey,
      isReverse: isReverse,
      isFixed: isFixed,
      amount: isReverse ? (toAmountValue !== '' ? toAmountValue : undefined) : (fromAmountValue !== '' ? fromAmountValue : undefined),
    }),
    enabled: isRefetchAmount,
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
    retry: 0,
    gcTime: 0,
  });


  useEffect(() => {
    if (isErrorAmount && errorAmount) {
      const Error = errorAmount as any;
      if (Error?.response?.data) {
        const errorData = JSON.parse(JSON.stringify(Error?.response?.data));
        if (errorData.code === 'EXCEEDING_MINIMUM_VALUE') {
          setBlockButtonExchange(true);
          setErrorInputAmount(`${t('exchange:widget.minValue')} ${String(errorData.data)}`)
          setMinMaxAmount(errorData.data)
        }
        else if (errorData.code === 'EXCEEDING_MAXIMUM_VALUE') {
          setBlockButtonExchange(true);
          setErrorInputAmount(`${t('exchange:widget.maxValue')} ${String(errorData.data)}`)
          setMinMaxAmount(errorData.data)
        } 
        else if (errorData.code === 'ERROR_VALIDATION') {
          setTriggeredRefetchAmount(true);
        }
      } else {
        setRefetchAmount(false);
      }
    }
  }, [isErrorAmount, errorAmount]);

  useEffect(() => {
    if (isSuccessPairs && pairsDataFetch !== undefined && isFirstReqAmount) {
      setPairsData(pairsDataFetch);
      setCurrencyFrom(pairsDataFetch.currencyFrom);
      setCurrencyTo(pairsDataFetch.currencyTo);
      setCurrencyFromKey(pairsDataFetch.currencyFromKey);
      setCurrencyToKey(pairsDataFetch.currencyToKey);
      setRefetchAmount(true);
    }
  }, [isSuccessPairs, pairsDataFetch, isFirstReqAmount]);

  useEffect(() => {
    if (isSuccessAmount && amountData?.amount.amount !== undefined) {
      setErrorInputAmount('')
      setRefetchAmount(true)
      setBlockButtonExchange(false);
      if (isFirstReqAmount) {
        setFromAmountValue(amountData.amount.depositLimitMin);
        setToAmountValue(amountData.amount.amount);
        setIsFirstReqAmount(false);
      } else if (isReverse) {
        setFromAmountValue(amountData.amount.amount);
      } else if (!isReverse) {
        setToAmountValue(amountData.amount.amount);
      }
    }
  }, [isSuccessAmount, amountData, isFirstReqAmount, isReverse]);

  useEffect(() => {
    if (triggeredRefetchAmount) {
      setRefetchAmount(true);
      refetchAmount();
      setTriggeredRefetchAmount(false);
    }
  }, [triggeredRefetchAmount]);

  const handleAmountMinMaxAmount = () => {
    if(!isReverse) {
      setFromAmountValue(String(getMinMaxAmount))
    } else {
      setToAmountValue(String(getMinMaxAmount))
    }
    setTriggeredRefetchAmount(true)
  }

  const handleAmountFromChange = (value: string) => {
    setFromAmountValue(value.trim());
    setIsReverse(false);
    setTriggeredRefetchAmount(true);
  };

  const handleAmountToChange = (value: string) => {
    const processedValue = value.replace('~', '').trim();
    setToAmountValue(processedValue);
    setIsReverse(true);
    setTriggeredRefetchAmount(true);
  };

  const handleSwap = () => {
    setErrorInputAmount('');
    const currencyFromData = currencyFrom;
    const currencyFromKeyData = currencyFromKey;
    const fromAmountValueData = fromAmountValue;

    setCurrencyFrom(currencyTo);
    setCurrencyFromKey(currencyToKey);
    setFromAmountValue(toAmountValue);

    setCurrencyTo(currencyFromData);
    setCurrencyToKey(currencyFromKeyData);
    setToAmountValue(fromAmountValueData);

    setTriggeredRefetchAmount(true);
  };

  const handleChangeFromCurrency = (data: CurrencyFromData | CurrencyToData) => {
    setCurrencyFrom(data);
    setCurrencyFromKey(data.key);
    const currencyToData = pairsData?.to[data.key] as NestedPairToData;
    const firstCurrencyToData = Object.values(currencyToData)[0];
    setCurrencyTo(firstCurrencyToData);
    setCurrencyToKey(firstCurrencyToData.key);
    setTriggeredRefetchAmount(true);
  };

  const handleChangeToCurrency = (data: CurrencyToData | CurrencyFromData) => {
    setCurrencyTo(data);
    setCurrencyToKey(data.key);
    setTriggeredRefetchAmount(true);
  };

  return (
    <WidgetBlock className='max-w-[628px] md:bg-glass sm:rounded-lg gap-2 md:p-6 p-0 md:border md:border-[#343436] shadow-md'>
      <WidgetBlock className='max-w-[628px] bg-[#1b1b1b] gap-2 rounded-lg pt-4 pr-4 pl-4 border-[#343436] border-2 mb-4'>

        <div className='flex flex-col gap-4 p-1'>
          <div className='flex flex-col gap-1'>
            <div className='flex justify-between items-center relative'>
              <span className='text-xl md-1:text-2xl font-bold'>{t('exchange:widget.youSend')}</span>
              <span onClick={() => handleAmountMinMaxAmount() } className='flex justify-end text-l md-1:text-xl font-bold text-red-600 cursor-pointer underline'>{!isReverse ? errorInputAmount : ''}</span>
            </div>
            <div className='flex justify-between items-center relative'>
              {!isSuccessPairs && (
                <div className='flex flex-col gap-2 md:min-w-[245px] md:max-w-[245px] sm:min-w-[224px] sm:max-w-[224px]'>
                  <Skeleton className='w-[107px] md:w-[155px] h-[65px] min-h-8 min-w-8' style={'rounded-xl'} />
                </div>
              )}
              {isSuccessPairs && pairsData && currencyFrom && (
                <div className='flex flex-col gap-2 md:min-w-[245px] md:max-w-[245px] sm:min-w-[224px] sm:max-w-[224px]'>
                  <ExchangeCurrency callback={handleChangeFromCurrency} coin={currencyFrom.currency} chain={currencyFrom.chain} name={currencyFrom.name} pairs={pairsData.from} pairsPopular={pairsData.popularPairs} isFixed={isFixed} isToPairs={false} />
                </div>
              )}
              {
                !isSuccessAmount ? (
                  <div className='flex flex-col gap-2 items-end md:min-w-[245px] md:max-w-[245px] md-1:min-w-[277px] md-1:max-w-[277px] sm:min-w-[250px] sm:max-w-[250px]'>
                    <Skeleton className='w-[150px] h-[30px] min-h-8 min-w-8 rounded' style={'rounded-xl'} classNameDiv={'sm:pr-5 pr-1'} />
                    <Skeleton className='w-[100px] h-[4px] min-h-4 min-w-8 rounded' style={'rounded-xl'} classNameDiv={'sm:pr-5 pr-1'} />
                  </div>
                ) : (
                  <div className="flex flex-col sm:pr-5 pr-1 md:min-w-[245px] md:max-w-[245px] md-1:min-w-[277px] md-1:max-w-[277px] sm:min-w-[250px] sm:max-w-[250px]">
                    <InputAmount
                      value={fromAmountValue}
                      onChange={(event) => handleAmountFromChange(event.target.value)}
                    />
                    {
                      isSuccessPairs && pairsData && (
                        <>
                          {
                            isSuccessAmount && amountData?.amount.depositInUsdt &&
                            <span className='text-right text-sm md-1:text-sm text-[#9ca3af]'>{
                              `~$${amountData.amount.depositInUsdt}`
                            }</span>
                          }
                        </>
                      )
                    }
                  </div>
                )
              }
            </div>

          </div>


          <div className='flex justify-center items-center relative border border-[#404040] border-solid'>
            <div className='flex bg-[#1B1B1B]  border border-solid rounded absolute border-[#404040] p-1 gap-1'>
              <Tooltip text={t('exchange:widget.swap')} minWidth='40px'>

                <button aria-label='swap' disabled={!isSuccessAmount} onClick={handleSwap} className='flex justify-center items-center '>
                  <SwapSvg width={25} height={25} className='stroke-[#d4d4d4] justify-center hover:stroke-[#949494] stroke-2' />
                </button>
              </Tooltip>
              <div className='border-x border-y border-[#525252] border-solid'></div>

              <button
                disabled={!isSuccessAmount || blockButtonExchange}
                className="flex justify-center items-center"
                onClick={() => setIsFixed(!isFixed)}
                aria-label='fixed'
              >
                {isFixed ? (
                  <Tooltip text={t('exchange:widget.isFixed')}>
                    <FixedActiveSvg width={25} height={25} className="stroke-[#c5426b] justify-center hover:stroke-[#f5b55c] stroke-2" />
                  </Tooltip>

                ) : (
                  <Tooltip text={t('exchange:widget.isFloat')}>
                    <FixedDisableSvg width={25} height={25} className="stroke-[#d4d4d4] hover:stroke-[#949494] stroke-2" />
                  </Tooltip>

                )}
              </button>
            </div>
          </div>


          <div className='flex flex-col gap-[8px]'>
            <div className='flex justify-between items-center relative'>
              <span className='text-xl md-1:text-2xl font-bold'>{t('exchange:widget.youReceive')}</span>
              <span onClick={() => handleAmountMinMaxAmount() } className='flex justify-end text-l md-1:text-xl font-bold text-red-600 cursor-pointer underline'>{isReverse ? errorInputAmount : ''}</span>
            </div>
            <div className='flex justify-between items-center relative'>
              {!isSuccessPairs && (
                <div className='flex flex-col gap-2 md:min-w-[245px] md:max-w-[245px] sm:min-w-[224px] sm:max-w-[224px]'>
                  <Skeleton className='w-[107px] md:w-[155px] h-[65px] min-h-8 min-w-8' style={'rounded-xl'} />
                </div>
              )}
              {isSuccessPairs && pairsData && currencyTo && (
                <div className='flex flex-col gap-2 md:min-w-[245px] md:max-w-[245px] sm:min-w-[224px] sm:max-w-[224px]'>
                  <ExchangeCurrency callback={handleChangeToCurrency} coin={currencyTo.currency} chain={currencyTo.chain} name={currencyTo.name} pairs={pairsData.to[currencyFromKey]} isFixed={isFixed} isToPairs={true} />
                </div>
              )}
              {
                !isSuccessAmount ? (
                  <div className='flex flex-col gap-2 items-end md:min-w-[245px] md:max-w-[245px] md-1:min-w-[277px] md-1:max-w-[277px] sm:min-w-[250px] sm:max-w-[250px]'>
                    <Skeleton className='w-[150px] h-[30px] min-h-8 min-w-8 rounded' style={'rounded-xl'} classNameDiv={'sm:pr-5 pr-1'} />
                    <Skeleton className='w-[100px] h-[4px] min-h-4 min-w-8 rounded' style={'rounded-xl'} classNameDiv={'sm:pr-5 pr-1'} />
                  </div>
                ) : (
                  <div className="flex flex-col sm:pr-5 pr-1 md:min-w-[245px] md:max-w-[245px] md-1:min-w-[277px] md-1:max-w-[277px] sm:min-w-[250px] sm:max-w-[250px]">

                    <InputAmount value={toAmountValue} onChange={(event) => handleAmountToChange(event.target.value)} readOnly={isFirstReqAmount} />
                    <>{isSuccessAmount && amountData && (
                      <span className='text-sm text-right underline '>
                        <Tooltip text={t('exchange:widget.textFee')} minWidth='170px'>
                          <div className="underline text-[#9ca3af] hover:text-[#d4d4d4]">
                            {t('exchange:widget.withoutFee', {
                              feeInfo: `${isFixed ? '' : '~'}${amountData.amount.withdrawalFeeAmount} ${currencyTo.currency}`
                            })}                        
                          </div>
                        </Tooltip>
                      </span>

                    )}</>
                  </div>
                )}

            </div>
          </div>
        </div>
      </WidgetBlock>

      <Button theme={!isSuccessAmount || blockButtonExchange ? 'error-go-exchange' : 'default'} height={62} textSize='text-2xl' disabled={!isSuccessAmount || blockButtonExchange} onClick={() => router.push(routerLink.exchange)}>
        {t('exchange:widget.button')}
      </Button>
    </WidgetBlock>
  );
};

export default ExchangeWidget;
